import ReactGA from 'react-ga';

// reference to https://github.com/react-ga/react-ga

export default function GAService(trackingId, isDebug) {
  const name = 'googleAnalytics';

  let isInit = false;
  if (trackingId) {
    ReactGA.initialize(trackingId, {
      trackingId,
      //debug,
      gaOptions: {
        cookieDomain: 'none',
      },
    });

    isInit = true;
  }

  let _gaShell = (functionName, fn) => {
    return (...args) => {
      if (!isInit) {
        return;
      }

      if (isDebug) {
        let msg = args.reduce(
          (acc, arg) =>
            acc + ' ' + (typeof arg === 'object' ? JSON.stringify(arg) : arg),
          ''
        );
        console.log(`[${functionName}] ${msg}`);
      }

      return fn(...args);
    };
  };

  return {
    name,
    actions: {},
    reducer: () => ({}),
    selectors: () => ({}),
    actionCreator: () => ({
      trackingPageView: _gaShell('trackingPageView', pathname =>
        ReactGA.pageview(pathname)
      ),
      trackingModalView: _gaShell('trackingModalView', modalName =>
        ReactGA.modalview(modalName)
      ),
      trackingUserAction: _gaShell('trackingUserAction', (...args) =>
        ReactGA.ga('send', 'event', ...args)
      ),
      trackingUserTiming: _gaShell('trackingUserTiming', (...args) =>
        ReactGA.ga('send', 'timing', ...args)
      ),
    }),
  };
}
