import Actions from "./Actions";

const initialState = {
  data: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_SITECONFIG_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
}

export function getSiteConfig(state) {
  return state.data;
}
