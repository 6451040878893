const ApiProxy = require("./ApiProxy");

module.exports = {
  async bootstrap() {
    return ApiProxy.get({
      path: "/api/bootstrap/"
    });
  },

  async login({ username, password }) {
    return ApiProxy.post({
      path: "/api/user/login/",
      data: { username, password }
    });
  },

  async socialSingin({ socialType, token }) {
    return ApiProxy.post({
      path: `/api/social_signin/${socialType}/`,
      data: { token }
    });
  },

  async getProfile(id) {
    return ApiProxy.get({
      path: `/api/user/profile/${id}/`
    });
  },

  async getMyProfile() {
    return ApiProxy.get({
      path: `/api/user/profile/`
    });
  },

  async editMyProfile(data) {
    let formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    return ApiProxy.put({
      path: "/api/user/profile/",
      data
    });
  },

  async register({ username, email, password }) {
    return ApiProxy.post({
      path: "/api/user/register/",
      data: { username, email, password }
    });
  },

  async resetValidation({ username, password }) {
    return ApiProxy.post({
      path: "/api/users/reset_validation/",
      data: {
        username,
        password
      }
    });
  },

  async changePassword({ username, password, newPassword }) {
    return ApiProxy.post({
      path: `/api/user/change_password/`,
      data: {
        username,
        password,
        new_password: newPassword
      }
    });
  },

  async resetPassword({ email }) {
    return ApiProxy.post({
      path: `/api/users/reset_password/`,
      data: {
        email
      }
    });
  },

  async fetchSiteConfigData() {
    return ApiProxy.get({
      path: `/api/site_config/1/`
    });
  },

  async createTag(data) {
    let secure = data.bindToAccount;
    delete data.bindToAccount;
    return ApiProxy.post({
      secure,
      path: `/api/tag/`,
      data
    });
  },

  async getTag(uid) {
    return ApiProxy.get({
      secure: true,
      path: `/api/tag/${uid}/`
    });
  },

  async editTag({ uid, name, url, bindAccount }) {
    if (bindAccount === false) {
      return ApiProxy.put({
        secure: true,
        path: `/api/tag/${uid}?bind_account=false`,
        data: {
          name,
          url
        }
      });
    }

    return ApiProxy.put({
      secure: true,
      path: `/api/tag/${uid}/`,
      data: {
        name,
        url
      }
    });
  },

  async fetchMyTags() {
    return ApiProxy.get({
      secure: true,
      path: `/api/tag/`
    });
  },

  async checkTag(uid) {
    return ApiProxy.get({
      path: `/api/tag/${uid}/`
    });
  }
};
