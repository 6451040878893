import React, { Component } from "react";
import "../index.css";
import styled from "styled-components";
import MuiWrapper from "../MuiWrapper";
import { Helmet } from "react-helmet";

class Layout extends Component {
  render() {
    return (
      <MuiWrapper>
        <Layout.Wrapper>
          <Helmet>
            <title>welcome to NFCTap.me</title>
            <meta name="description" content="WASHOW NetME 每天都要感應智慧" />
            <link rel="shortcut icon" href="/favicon-logo.ico" />
          </Helmet>

          <Layout.Content>{this.props.children}</Layout.Content>
        </Layout.Wrapper>
      </MuiWrapper>
    );
  }
}

Layout.Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
`;

Layout.Content = styled.div`
  flex: 1;
  align-self: stretch;
`;

export default Layout;
