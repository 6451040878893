import { push } from "gatsby";
import { getStore } from "./Store";
import Actions from "./Reducers/Actions";
import ApiProxy from "./ApiProxy";
import Catchify from "./Utils/Catchify";
import API from "./Api";
import * as Services from "./Services";

export default dispatch => ({
  navActions: {
    push: Catchify(async url => {
      push(`/${url}`);
    })
  },

  appActions: {
    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    login: Catchify(
      async ({ username, password }) => {
        let response = await API.login({ username, password });
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem("token", response.token);
          window.localStorage.setItem("id", response.id);
        } catch (err) {
          console.log("cannot set localStorage! ");
        }
        dispatch({ type: Actions.LOGIN, payload: response });
      },

      async err => {
        ApiProxy.setToken(null);
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("token");
        console.warn(err);
        throw err;
      }
    ),

    getMyProfile: Catchify(
      async () => {
        let response = await API.getMyProfile();
        dispatch({ type: Actions.AUTO_LOGIN, payload: response });
      },

      async err => {
        console.warn(err);
        throw err;
      }
    ),

    autoLogin: Catchify(
      async () => {
        let token = window.localStorage.getItem("token");
        ApiProxy.setToken(token);
        if (token) {
          let response = await API.getMyProfile();
          dispatch({ type: Actions.AUTO_LOGIN, payload: response });
        }
      },

      async err => {
        console.warn(err);
        ApiProxy.setToken(null);
        window.localStorage.removeItem("token");
        throw err;
      }
    ),

    logout: Catchify(async () => {
      ApiProxy.setToken(null);
      window.localStorage.removeItem("id");
      window.localStorage.removeItem("token");
      dispatch({ type: Actions.LOGOUT, payload: null });
    }),

    register: async ({ username, email, password }) => {
      try {
        await API.register({ username, email, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetPassword: async ({ email }) => {
      try {
        await API.resetPassword({ email });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetValidation: async ({ username, password }) => {
      try {
        await API.resetValidation({ username, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    socialSingin: async ({ socialType, token }) => {
      try {
        let response = await API.socialSingin({ socialType, token });
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem("token", response.token);
          window.localStorage.setItem("id", response.id);
        } catch (err) {
          console.log("cannot set localStorage! ");
        }
        dispatch({ type: Actions.LOGIN, payload: response });
        return Promise.resolve();
      } catch (err) {
        ApiProxy.setToken(null);
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("token");
        console.warn(err);
        return Promise.reject(err);
      }
    },

    editMyProfile: Catchify(
      async data =>
        dispatch({
          type: Actions.AUTO_LOGIN,
          payload: await API.editMyProfile(data)
        }),
      err => Promise.reject(err)
    ),

    fetchSiteConfigData: Catchify(
      async () => {
        let result = await API.fetchSiteConfigData();
        dispatch({ type: Actions.FETCH_SITECONFIG_SUCCESS, payload: result });
      },
      err => Promise.reject(err)
    ),

    createTag: Catchify(
      async data => {
        let result = await API.createTag(data);
        return result;
      },
      err => Promise.reject(err)
    ),

    getTag: Catchify(
      async uid => {
        let result = await API.getTag(uid);
        return result;
      },
      err => Promise.reject(err)
    ),

    editTag: Catchify(
      async ({ uid, name, url, bindAccount }) => {
        let result = await API.editTag({ uid, name, url, bindAccount });
        return result;
      },
      err => Promise.reject(err)
    ),

    fetchMyTags: Catchify(
      async () => {
        let result = await API.fetchMyTags();
        return result;
      },
      err => Promise.reject(err)
    ),

    checkTag: Catchify(
      async uid => {
        let result = await API.checkTag(uid);
        return result;
      },
      err => Promise.reject(err)
    ),

    setLocalSettingUid: uid => {
      console.log("SET local uid!!", uid);
      window.localStorage.setItem("uid", uid);
    },

    getLocalSettingUid: () => {
      // TODO: clear uid after successfully set
      console.log("get local uid", window.localStorage.getItem("uid"));
      return window.localStorage.getItem("uid");
    },
    clearLocalSettingUid: () => {
      console.log("remove!!! local uid");
      window.localStorage.removeItem("uid");
    },

    ...Services.getActionCreators(getStore())
  }
});
