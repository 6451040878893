import { combineReducers } from "redux";
import user from "./User";
import lang from "./Lang";
import product from "./Product";
import order from "./Order";
import siteConfig from "./SiteConfig";
import { getReducers } from "../Services";

export default combineReducers({
  user,
  lang,
  product,
  order,
  siteConfig,
  ...getReducers()
});
