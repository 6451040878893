import CartServiceFactory from "./Cart";
import CatalogServiceFactory from "./Catalog";
import GAServiceFactory from "./GoogleAnalytics";
import Config from "../Domain/constants";
import { namedExportWithMemorizedOnce } from "../Utils/FpUtil";

const Services = {
  cart: CartServiceFactory(Config.cart),
  [CatalogServiceFactory().name]: CatalogServiceFactory(Config.catalog),
  ga: GAServiceFactory(Config.googleAnalyticsId, Config.debug)
};

function getReducers() {
  return Object.keys(Services).reduce((acc, serviceName) => {
    acc[serviceName] = Services[serviceName].reducer;
    return acc;
  }, {});
}

function getActionCreators(store) {
  return Object.keys(Services).reduce((acc, serviceName) => {
    acc[serviceName] = Services[serviceName].actionCreator(store);
    return acc;
  }, {});
}

function getSelectors(state) {
  return Object.keys(Services).reduce((acc, serviceName) => {
    let selectors = Services[serviceName].selectors(state);
    acc[serviceName] = namedExportWithMemorizedOnce(selectors);
    return acc;
  }, {});
}

export { getReducers, getActionCreators, getSelectors };
