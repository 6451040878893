import React from "react";
import { Provider } from "react-redux";
import Layout from "../Layout";
import { getStore } from "../Store";
import ActionCreator from "../ActionCreator";

async function dispatchRouteInitClientActions({ store, location }) {
  let { appActions } = ActionCreator(store.dispatch);

  if (!store.getState().user.data) {
    await appActions.autoLogin();
  }

  appActions.fetchSiteConfigData().catch(err => console.warn(err));
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
    console.warn("*** Error Boundary ***", error, info);
  }

  render() {
    let { error } = this.state;

    if (error) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ padding: 20, backgroundColor: "grey" }}>
            <h2 style={{ color: "white" }}>Oops! We have errors!</h2>
            <p style={{ color: "#ddd" }}>Please reload the page</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

class Page extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <Provider store={this.store}>
          <Layout key={this.props.pageContext.lang} {...this.props}>
            {this.props.children}
          </Layout>
        </Provider>
      </ErrorBoundary>
    );
  }

  UNSAFE_componentWillMount() {
    this.store = getStore();
    let appActions = ActionCreator(this.store.dispatch).appActions;
    appActions.setLang(this.props.pageContext.lang);
  }

  async componentDidMount() {
    let { location } = this.props;
    let appActions = ActionCreator(this.store.dispatch).appActions;
    appActions.ga.trackingPageView(location.pathname + location.search);
    await dispatchRouteInitClientActions({
      store: getStore(),
      location: this.props.location
    });
  }
}

export function withPage(Comp) {
  class PageWrapper extends React.Component {
    render() {
      return (
        <Page {...this.props}>
          <Comp {...this.props}>{this.props.children}</Comp>
        </Page>
      );
    }
  }

  PageWrapper.displayName = "Page-" + Comp.displayName;
  return PageWrapper;
}

export default Page;
